import * as React from "react";
import styles from "@styles/pages/404-not-found/index.module.scss"
import Image from "next/image";
import Button from "@components/button/Button";
import Link from "next/link";
import {useRouter} from 'next/router'
import {useEffect} from "react";

export default function NotFound() {

    /**
     * Setting the title of the page
     */
    useEffect(() => {
        const prevTitle = document.title
        document.title = 'Not Found | SIDR®'
        return () => {
            document.title = prevTitle
        }
    })

    /**
     * this variable is used to reload the page
     */
    const router = useRouter()

    /**
     * Try again button click action
     * reloading the current page
     */
    const reloadPage = () => {
        router.reload()
    }

    /**
     * Not found page
     * When url didn't math this page is visible to the user
     */
    return (
        <>
            <div className={styles.container}>
                <Image src={'/assets/images/decorations/extra/404_not_found_icon.webp'} alt={'not found'} height={120}
                       width={100}></Image>
                <span>404</span>
                <span className={'not_found'}>not found</span>
                <p>
                    Sorry, we can’t seem to find the page you are looking for.
                    Please try again later on.
                </p>
                <Button classes={'button'} onClick={reloadPage}>try again</Button>
                <Link href={'/'}><span>back to home</span></Link>
            </div>
        </>
    )
}
